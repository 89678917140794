.comment,
.reply {
  padding: 8px 6px;
  background-color: #f8f9fe;
  border-radius: 7px;
}

.bg-gradient-info {
  background: linear-gradient(87deg, #f6343f 0, #f6343f 100%) !important;
}

.description {
  height: 300px !important;
}

.active-box {
  background-color: #f23742;
  transition: background-color 0.5s;
}

.center-nav {
  justify-content: center !important;
}

.active-box h1,
.active-box h5 {
  color: white !important;
  transition: color 0.5s;
}

/*
.fill-default {
  fill: #333 !important;
}
*/

.nice-dates-navigation,
.nice-dates-day {
  color: #111;
}
.nice-dates-popover {
  box-shadow: none;
  border: 1px solid #ddd;
  border-radius: 2px;
  max-width: 480px;
  transition: none;
}

.navbar-top {
  z-index: -4 !important;
}

.active-image {
  border: solid thick #f23742;
  transition: border 0.2s;
}

.nice-dates-day:before {
  background-color: white !important;
  border: solid #f67c17;
}

.nice-dates-day.-selected:not(:hover) *,
.nice-dates-day.-selected.-disabled * {
  color: #333 !important;
}

.input {
  display: block;
  width: 100%;
  height: calc(2.75rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  margin-bottom: 17px;
}
.bg-default {
  background-color: #333 !important;
}

.list:after {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 98%;
  content: "";
  background: linear-gradient(to top, rgba(255, 255, 255, 1) 5%, rgba(255, 255, 255, 0) 95%);
  pointer-events: none; /* so the text is still selectable */
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 800ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 800ms ease-in;
}

p {
  font-weight: 400;
}

body {
  background-color: white !important;
}

footer {
  background-color: white !important;
}
